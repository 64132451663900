import React from "react"
import PageTemplate from "../templates/pages/pageTemplate"

export default function Imprint(props) {
  return (
    <PageTemplate title="Impressum">
      <div className="mb-4">
        <h4>Angaben gemäß § 5 TMG</h4>
        ArtGreetings
        <br />
        Inhaber: Lisa Tölle
        <br />
        Tegelweg 3a
        <br />
        33102 Paderborn
      </div>
      <div className="mb-4">
        <h4>Kontakt</h4>
        email: info@artgreetings.de
        <br />
        Telefon: 05251 5395350
        <br />
        Website: www.artgreetings.de
      </div>
      <div className="mb-4">
        <h4>Umsatzsteuer-Identifikationsnummer</h4>
        UID:
      </div>
      <div className="mb-4">
        <h4>Streitschlichtung</h4>
        Die Europäische Kommission stellt eine Plattform zur
        Online-Streitbeilegung (OS) bereit:{" "}
        <a href="https://ec.europa.eu/odr" target="_blanc">
          https://ec.europa.eu/odr
        </a>
        <br />
        Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren
        vor einer Verbraucherschlichtungsstelle teilzunehmen.
      </div>
    </PageTemplate>
  )
}
